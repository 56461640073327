var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.AppPage,{attrs:{"title":"Missions"},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('p',{staticClass:"grey--text m-0 p-0"},[_vm._v("Track and stay up to date on client missions")])]},proxy:true},{key:"tools",fn:function(){return [(_vm.can('create_mission_lead'))?_c('PrimaryButton',{attrs:{"vIcon":"la-plus"},on:{"click":function($event){_setup.showAddLeadForm = true}}},[_vm._v(" Add lead ")]):_vm._e()]},proxy:true}])},[_c(_setup.Table,{ref:"table",staticClass:"missions-list-table",attrs:{"query":_setup.query,"queryKey":"missionLifecycles","headers":_setup.headers,"exportOptions":{
      headers: [
        {
          text: 'Name',
          value: 'name',
        },
        { text: 'Summary', value: 'summary' },
        { text: 'Client', value: 'client.name' },
        { text: 'State', value: 'state' },
        { text: 'Created', value: 'createdAt' },
      ],
    },"listFilters":[
      {
        field: 'state',
        label: 'Stage',
        filters: {
          expression_of_interest: 'Expression of interest',
          request_for_proposal: 'Request for proposal',
          lead_stage: 'Lead',
          scoping_stage: 'Scoping',
          proposal_stage: 'Proposal',
          contracting_stage: 'Contracting',
          staffing_stage: 'Staffing',
          execution_stage: 'Execution',
          completed: 'Completed',
          archived: 'Archived',
          paused: 'Paused',
        },
      },
      {
        field: 'country',
        label: 'Country',
        queryFilter: {
          query: _setup.countriesQuery,
          itemValue: 'name',
          listKey: 'countries',
          per: 200,
          returnObject: false,
        },
        icon: 'la-flag',
      },
      {
        field: 'clientId',
        label: 'Client',
        queryFilter: {
          query: _setup.listClientsQuery,
          itemValue: 'id',
          listKey: 'clients',
          per: 200,
          returnObject: true,
        },
        icon: 'la-city',
      },
      {
        field: 'missionExecutiveId',
        label: 'Mission Executive',
        position: 'left',
        queryFilter: {
          query: _setup.consultantsSearchQuery,
          itemValue: 'id',
          listKey: 'consultants',
          returnObject: true,
          variables: { per: 10, jobTitle: 'mission executive' },
        },
        icon: 'la-user',
      },
    ],"dateFilters":[
      {
        field: 'createdAtDate',
        label: 'Created',
      },
    ],"searchFilters":[
      {
        field: 'search',
        label: 'Search',
      },
    ],"itemActions":_setup.itemActions},on:{"click:row":_setup.gotoDetailPage},scopedSlots:_vm._u([{key:`item.name`,fn:function({ item }){return [_c('div',{staticClass:"mission-name pt-4"},[_c('span',[_vm._v(_vm._s(item.name || "Open Lead"))])]),_c('subtitle',{staticClass:"pt-0 grey--text text--darken-2",staticStyle:{"max-width":"50ch"}},[_c('ExpandableHtmlBlock',{attrs:{"content":item.summary,"limit":150,"showToggle":false}})],1)]}},{key:`item.client`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.truncate(item.client.name, 20))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.client.name))])])]}},{key:`item.manager`,fn:function({ item }){return [(item.primaryManager)?_c(_setup.UserChip,{attrs:{"user":item.primaryManager}}):_c('span',{staticClass:"text-center"},[_vm._v(" Not assigned ")])]}},{key:`item.state`,fn:function({ item }){return [_c('state-chip',{staticStyle:{"margin-left":"0 !important"},attrs:{"color":_setup.getStateColor(item.state),"state":item.state.replaceAll('_', ' ').replace('stage', '')}})]}},{key:`item.scopeService`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.scopeService?.name || "N/A")+" ")]}}],null,true)}),_c(_setup.NewProjectWizard,{attrs:{"backText":_setup.t('backToMissions'),"wizardTitle":_setup.t('newLead'),"wizardSubtitle":_setup.t('newLeadSubtitle'),"refetchQueries":_setup.queries,"isStaff":true},on:{"close":function($event){_setup.showAddLeadForm = false}},model:{value:(_setup.showAddLeadForm),callback:function ($$v) {_setup.showAddLeadForm=$$v},expression:"showAddLeadForm"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }